'use client'

import { type AxiosError } from 'axios'
import { type SWRResponse } from 'swr'
import useImmutableSwr from 'swr/immutable'

import {
  ReavailabilityNotificationControllerApiFactory,
  type ReavailabilityNotificationServiceStatusResponseV1,
} from '@redteclab/api/clients/bully'

import { useBullyApi } from '../../../base-api-communication'
import { useGlobalConfigContext } from '../../../global-config'

const getReavailabilityNotificationGetSubscriptionStatusKey = (
  productId: string,
): string => `ReavailabilityNotificationGetSubscriptionStatus${productId}`

export const useApiSwrReavailabilityNotificationGetSubscriptionStatus =
  (options: {
    productId: string
  }): SWRResponse<
    ReavailabilityNotificationServiceStatusResponseV1,
    AxiosError<unknown, unknown>
  > => {
    const api = useBullyApi(ReavailabilityNotificationControllerApiFactory)
    const globalConfig = useGlobalConfigContext()

    const fetcher =
      async (): Promise<ReavailabilityNotificationServiceStatusResponseV1> => {
        const response =
          await api.reavailabilityV1TenantCustomerSubscriptionStatusUpidGet({
            tenant: globalConfig.tenantAndEnv,
            upid: options.productId,
          })

        return response.data
      }

    return useImmutableSwr(
      getReavailabilityNotificationGetSubscriptionStatusKey(options.productId),
      fetcher,
    )
  }
